/* eslint-disable @typescript-eslint/no-explicit-any */
/*= ========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== */

import Vue from 'vue'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// Vuesax Component Framework
import Vuesax from 'vuesax'
import VeeValidate from 'vee-validate'

import 'material-icons/iconfont/material-icons.css' // Material Icons
import 'vuesax/dist/vuesax.css'

// axios

// Theme Configurations
import '../themeConfig'

// Globally Registered Components
import './globalComponents'

// Styles: SCSS
import './assets/scss/main.scss'

// Tailwind
import '@/assets/css/main.css'

// Vue Router
import { VueHammer } from 'vue2-hammer'

// Vuejs - Vue wrapper for hammerjs

// Vue Good Table

import Toast from 'vue-toastification'
import GlobalMixin from '@/mixins/global'

import { createPinia, PiniaVuePlugin } from 'pinia'

import PrimeVue from 'primevue/config'

import 'primevue/resources/themes/saga-blue/theme.css' // theme
import 'primevue/resources/primevue.min.css' // core css
import 'primeicons/primeicons.css' // icons

import vuetify from '@/plugins/vuetify'
import '@/assets/scss/vuetify-variables.scss'
import router from './router'

// Import the CSS or use your own!
import 'vue-toastification/dist/index.css'

import App from './App.vue'

import $axios from './axios-instance'

// Vuex Store
import store from './store/store'

const options = {
  // You can set your default options here
  timeout: 5000,
  transition: 'my-custom-fade',
  maxToasts: 20,
  newestOnTop: true,
  position: 'bottom-right',
  showCloseButtonOnHover: true,
  hideProgressBar: true,
  closeButton: 'button',
  closeOnClick: false,
  pauseOnFocusLoss: false,
  pauseOnHover: false,
}

Vue.use(Toast, options)

Vue.use(PiniaVuePlugin)

const pinia = createPinia()

Vue.prototype.$axios = $axios
Vue.use(VeeValidate) // Vuesax
Vue.use(Vuesax)
Vue.use(VueHammer)
Vue.use(Loading)
Vue.use(PrimeVue)

// Feather font icon
require('./assets/css/iconfont.css')

Vue.mixin(GlobalMixin)

function formatNumber(n) {
  // format number 1000000 to 1,234,567
  return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

function formatCurrency(vNode, roundTo, blur) {
  // appends $ to value, validates decimal side
  // and puts cursor back in right position.

  const val = `${vNode.componentInstance.value}`

  // get input value
  let inputVal = val

  const inputRef = vNode.componentInstance.$refs.vsinput

  const inputRoundTo = roundTo !== undefined && roundTo !== null ? roundTo : 2

  // don't validate empty input
  if (inputVal === '') {
    return
  }

  // original length
  const originalLen = inputVal.length

  // initial caret position
  let caretPOS = inputRef.selectionStart

  // check for decimal
  if (inputVal.indexOf('.') >= 0) {
    // get position of first decimal
    // this prevents multiple decimals from
    // being entered
    const decimalPOS = inputVal.indexOf('.')

    // split number by decimal point
    let leftSide = inputVal.substring(0, decimalPOS)
    let rightSide = inputVal.substring(decimalPOS)

    // add commas to left side of number
    leftSide = formatNumber(leftSide)

    // validate right side
    rightSide = rightSide.replace(/\D/g, '')

    // On blur make sure 2 numbers after decimal
    if (blur === 'blur') {
      rightSide += '0'.repeat(inputRoundTo)
    }

    // Limit decimal to only 2 digits
    rightSide = rightSide.substring(0, inputRoundTo)

    // join number by .
    inputVal = `$${leftSide}.${rightSide}`
  } else {
    // no decimal entered
    // add commas to number
    // remove all non-digits
    inputVal = formatNumber(inputVal)
    inputVal = `$${inputVal}`

    // final formatting
    if (blur === 'blur' && inputRoundTo !== 0) {
      inputVal += `.${'0'.repeat(inputRoundTo)}`
    }
  }

  // send updated string to input
  vNode.componentInstance.$emit('input', inputVal)

  // put caret back in the right position
  const updatedLen = inputVal.length
  caretPOS = updatedLen - originalLen + caretPOS
  inputRef.setSelectionRange(caretPOS, caretPOS)
  // vNode.componentInstance.$refs.vsinput.setSelectionRange(caretPOS, caretPOS)
}

Vue.directive('currency', {
  // TODO: make it work on update as well
  // componentUpdated: function (el, binding, vNode) {
  //   formatCurrency(vNode, binding.value)
  // },
  bind(el, binding, vNode: any) {
    vNode.componentInstance.$on('blur', () => {
      formatCurrency(vNode, binding.value, 'blur')
    })
  },
})

function formatPercentage(vNode, roundTo, blur) {
  // appends % to value, validates decimal side
  // and puts cursor back in right position.

  const val = `${vNode.componentInstance.value}`

  // get input value
  let inputVal = val

  const inputRoundTo = roundTo !== undefined && roundTo !== null ? roundTo : 2

  // don't validate empty input
  if (inputVal === '') {
    return
  }

  // check for decimal
  if (inputVal.indexOf('.') >= 0) {
    // get position of first decimal
    // this prevents multiple decimals from
    // being entered
    const decimalPOS = inputVal.indexOf('.')

    // split number by decimal point
    let leftSide = inputVal.substring(0, decimalPOS)
    let rightSide = inputVal.substring(decimalPOS)

    // add commas to left side of number
    leftSide = formatNumber(leftSide)

    // validate right side
    rightSide = rightSide.replace(/\D/g, '')

    // On blur make sure 2 numbers after decimal
    if (blur === 'blur') {
      rightSide += '0'.repeat(inputRoundTo)
    }

    // Limit decimal to only 2 digits
    rightSide = rightSide.substring(0, inputRoundTo)

    // join number by .
    inputVal = `${leftSide}.${rightSide}`
  } else {
    // no decimal entered
    // add commas to number
    // remove all non-digits
    inputVal = formatNumber(inputVal)

    // final formatting
    if (blur === 'blur' && inputRoundTo !== 0) {
      inputVal += `.${'0'.repeat(inputRoundTo)}`
    }
  }

  inputVal += '%'

  // send updated string to input
  vNode.componentInstance.$emit('input', inputVal)
}

Vue.directive('percentage', {
  // TODO: make it work on update as well
  // componentUpdated: function (el, binding, vNode) {
  //   formatCurrency(vNode, binding.value)
  // },
  bind(el, binding, vNode: any) {
    vNode.componentInstance.$on('blur', () => {
      formatPercentage(vNode, binding.value, 'blur')
    })
  },
})

function formatNum(vNode, roundTo, blur) {
  // appends % to value, validates decimal side
  // and puts cursor back in right position.

  const val = `${vNode.componentInstance.value}`

  // get input value
  let inputVal = val

  const inputRoundTo = roundTo !== undefined && roundTo !== null ? roundTo : 2

  // don't validate empty input
  if (inputVal === '') {
    return
  }

  // check for decimal
  if (inputVal.indexOf('.') >= 0) {
    // get position of first decimal
    // this prevents multiple decimals from
    // being entered
    const decimalPOS = inputVal.indexOf('.')

    // split number by decimal point
    let leftSide = inputVal.substring(0, decimalPOS)
    let rightSide = inputVal.substring(decimalPOS)

    // add commas to left side of number
    leftSide = formatNumber(leftSide)

    // validate right side
    rightSide = rightSide.replace(/\D/g, '')

    // On blur make sure 2 numbers after decimal
    if (blur === 'blur') {
      rightSide += '0'.repeat(inputRoundTo)
    }

    // Limit decimal to only 2 digits
    rightSide = rightSide.substring(0, inputRoundTo)

    // join number by .
    inputVal = `${leftSide}.${rightSide}`
  } else {
    // no decimal entered
    // add commas to number
    // remove all non-digits
    inputVal = formatNumber(inputVal)

    // final formatting
    if (blur === 'blur' && inputRoundTo !== 0) {
      inputVal += `.${'0'.repeat(inputRoundTo)}`
    }
  }

  // send updated string to input
  vNode.componentInstance.$emit('input', inputVal)
}

Vue.directive('number', {
  // TODO: make it work on update as well
  // componentUpdated: function (el, binding, vNode) {
  //   formatCurrency(vNode, binding.value)
  // },
  bind(el, binding, vNode: any) {
    vNode.componentInstance.$on('blur', () => {
      formatNum(vNode, binding.value, 'blur')
    })
  },
})

Vue.config.productionTip = false

// import * as Sentry from "@sentry/vue";
// import { Integrations } from "@sentry/tracing";

// Sentry.init({
//   Vue,
//   dsn: "https://c7d957daaf724067b1c484354c4c34f4@o515970.ingest.sentry.io/5621649",
//   integrations: [new Integrations.BrowserTracing()],

//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: 1.0,
// });

new Vue({
  router,
  store,
  vuetify,
  pinia,
  render: (h) => h(App),
}).$mount('#app')

const routes = [
  {
    path: '/tools/files',
    name: 'files',
    component: () => import('@/views/toolsModule/fileSubModule/views/FilesPage.vue'),
    meta: {
      breadcrumb: [
        { title: 'Dashboard', url: '/' },
        { title: 'Tools' },
        { title: 'Files', active: true },
      ],
      pageTitle: 'Files',
      requiresAuth: true,
      permissions: ['createFile', 'createFolder', 'getFile', 'renameFile', 'deleteFile'],
    },
  },
]

export default routes

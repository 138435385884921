import Vue from 'vue'
import Router from 'vue-router'
import axios from 'axios'
import store from '@/store/store'
import ToolsModuleRoutes from '@/views/toolsModule/fileSubModule/routes'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  base: '/',
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: '',
      component: () => import('@/layouts/main/Main.vue'),
      children: [
        // =============================================================================
        // Dashboard Routes
        // =============================================================================
        {
          path: '/',
          name: 'home',
          component: () => import('@/views/home.vue'),
          meta: {
            breadcrumb: [{ title: 'Dashboard', url: '/' }],
            pageTitle: 'Dashboard',
            requiresAuth: true,
            permissions: ['default'],
          },
        },
        // =============================================================================
        // Admin Routes
        // =============================================================================
        {
          path: '/admin/createGroup',
          name: 'create-group',
          component: () => import('@/views/adminModule/managePermissions/createGroup.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Permissions', url: '/admin/permissions' },
              { title: 'Create Group', active: true },
            ],
            pageTitle: 'Create Group',
            requiresAuth: true,
            permissions: ['managePermissions'],
          },
        },
        {
          path: '/admin/permissions',
          name: 'manage-permissions',
          component: () => import('@/views/adminModule/managePermissions/listGroups.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Permissions', active: true },
            ],
            pageTitle: 'Manage Permissions',
            requiresAuth: true,
            permissions: ['managePermissions'],
          },
        },
        {
          path: '/admin/permissions/:groupID',
          name: 'manage-permission',
          component: () => import('@/views/adminModule/managePermissions/editGroup.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Permissions', url: '/admin/permissions' },
              { title: 'Edit Group', active: true },
            ],
            pageTitle: 'Edit Group',
            requiresAuth: true,
            permissions: ['managePermissions'],
          },
        },
        {
          path: '/admin/forms',
          name: 'manage-forms',
          component: () =>
            import('@/views/adminModule/manageForms/formTemplates/FormTemplates.vue'),
          meta: {
            breadcrumb: [
              { title: 'Admin', url: '/' },
              { title: 'Forms' },
              { title: 'Manage Forms', active: true },
            ],
            pageTitle: 'Manage Forms',
            requiresAuth: true,
            permissions: ['manageForms'],
          },
        },
        {
          path: '/admin/forms/create/',
          name: 'create-form',
          component: () => import('@/views/adminModule/manageForms/formBuilder/FormBuilder.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Forms' },
              { title: 'Manage Forms', url: '/admin/forms' },
              { title: 'Create', active: true },
            ],
            pageTitle: 'Create Form',
            requiresAuth: true,
            permissions: ['manageForms'],
          },
        },
        {
          path: '/admin/forms/:id',
          name: 'edit-form',
          component: () => import('@/views/adminModule/manageForms/formBuilder/FormBuilder.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Forms' },
              { title: 'Manage Forms', url: '/admin/forms' },
              { title: 'Edit', active: true },
            ],
            pageTitle: 'Edit Form',
            requiresAuth: true,
            permissions: ['manageForms'],
          },
        },
        {
          path: '/bugs/report',
          name: 'bug-report',
          component: () => import('@/views/adminModule/manageBugs/manageBugs.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'User' },
              { title: 'View', active: true },
            ],
            pageTitle: 'Bug Report',
            requiresAuth: false,
            permissions: ['default'],
          },
        },
        // =============================================================================
        // RFQ Routes
        // =============================================================================
        {
          path: '/rfq/create',
          name: 'create-rfq',
          component: () => import('@/views/rfqModule/createRFQ/createRFQ.vue'),
          meta: {
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'RFQ' },
              { title: 'Create', active: true },
            ],
            pageTitle: 'Create RFQ',
            requiresAuth: true,
            permissions: ['createRfq'],
          },
        },
        {
          path: '/rfq/duplicate/:rfqID',
          name: 'duplicate-rfq',
          component: () => import('@/views/rfqModule/duplicateRFQ/duplicateRFQ.vue'),
          meta: {
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'RFQ' },
              { title: 'Duplicate', active: true },
            ],
            pageTitle: 'Duplicate RFQ',
            requiresAuth: true,
            permissions: ['createRfq'],
          },
        },
        {
          path: '/rfq/all',
          name: 'all-rfqs',
          component: () => import('@/views/rfqModule/listRFQ/listRFQ.vue'),
          meta: {
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'RFQ' },
              { title: 'All RFQs', active: true },
            ],
            pageTitle: 'All RFQs',
            requiresAuth: true,
            permissions: ['getRfq'],
          },
        },
        {
          path: '/customerRFQs',
          name: 'my-rfqs',
          component: () => import('@/views/rfqModule/listRFQ/listRFQ.vue'),
          meta: {
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'RFQ' },
              { title: 'View RFQs', active: true },
            ],
            pageTitle: 'View RFQs',
            requiresAuth: true,
            permissions: ['getRfq'],
          },
        },
        {
          path: '/customerRFQs/:rfqID',
          name: 'my-rfq',
          component: () => import('@/views/rfqModule/customerViewRFQ/viewRFQ.vue'),
          meta: {
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'RFQ' },
              { title: 'View RFQ', active: true },
            ],
            pageTitle: 'View RFQ',
            requiresAuth: true,
            permissions: ['getRfq'],
          },
        },
        {
          path: '/rfq/queue/reviewRfq',
          name: 'reviewRfq-queue',
          component: () => import('@/views/rfqModule/listRFQ/listRFQ.vue'),
          meta: {
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'RFQ' },
              { title: 'Process' },
              { title: 'Review RFQ', active: true },
            ],
            pageTitle: 'Review RFQ',
            requiresAuth: true,
            permissions: ['getReviewRfq'],
          },
        },
        {
          path: '/rfq/queue/engineeringAnalysis',
          name: 'engineeringAnalysis-queue',
          component: () => import('@/views/rfqModule/listRFQ/listRFQ.vue'),
          meta: {
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'RFQ' },
              { title: 'Process' },
              { title: 'Engineering Analysis', active: true },
            ],
            pageTitle: 'Engineering Analysis',
            requiresAuth: true,
            permissions: ['createEngData'],
          },
        },
        {
          path: '/rfq/queue/supplierSourcing',
          name: 'supplierSourcing-queue',
          component: () => import('@/views/rfqModule/listRFQ/listRFQ.vue'),
          meta: {
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'RFQ' },
              { title: 'Process' },
              { title: 'Supplier Sourcing', active: true },
            ],
            pageTitle: 'Supplier Sourcing',
            requiresAuth: true,
            permissions: ['createSupplierSourcing'],
          },
        },
        {
          path: '/rfq/queue/quoteComparison',
          name: 'quoteComparison-queue',
          component: () => import('@/views/rfqModule/listRFQ/listRFQ.vue'),
          meta: {
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'RFQ' },
              { title: 'Process' },
              { title: 'Quote Comparison', active: true },
            ],
            pageTitle: 'Quote Comparison',
            requiresAuth: true,
            permissions: ['createQuoteComparison'],
          },
        },
        {
          path: '/rfq/queue/quoteCreation',
          name: 'quoteCreation-queue',
          component: () => import('@/views/rfqModule/listRFQ/listRFQ.vue'),
          meta: {
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'RFQ' },
              { title: 'Process' },
              { title: 'Quote Creation', active: true },
            ],
            pageTitle: 'Quote Creation',
            requiresAuth: true,
            permissions: ['createQuote'],
          },
        },
        {
          path: '/rfq/process/:rfqID',
          name: 'process-rfq',
          component: () => import('@/views/rfqModule/processRFQ/processRFQ.vue'),
          meta: {
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'RFQ' },
              { title: 'Process RFQ', active: true },
            ],
            pageTitle: 'Process RFQ',
            requiresAuth: true,
            permissions: ['default'],
          },
        },
        {
          path: '/rfq/process/:rfqID/edit',
          name: 'process-engineeringAnalysis',
          component: () => import('@/views/rfqModule/processRFQ/processRFQ.vue'),
          meta: {
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'RFQ' },
              { title: 'Process RFQ', active: true },
            ],
            pageTitle: 'Process RFQ',
            requiresAuth: true,
            permissions: ['updateEngData'],
          },
        },
        // ============================================================================
        // Customer Portal
        // ============================================================================
        {
          path: '/customerPortal/accounts-request/list',
          name: 'accounts-request-list',
          component: () => import('@/views/customerPortalModule/listAccountRequests.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'All Account Requests', active: true },
            ],
            pageTitle: 'All Account Requests',
            requiresAuth: true,
            permissions: ['getAccountInvite'],
          },
        },
        {
          path: '/customerPortal/accounts/list',
          name: 'accounts-list',
          component: () => import('@/views/customerPortalModule/listAccounts.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'All Accounts', active: true },
            ],
            pageTitle: 'All Accounts',
            requiresAuth: true,
            permissions: ['getAccountInvite'],
          },
        },
        {
          path: '/customerPortal/account-invites/list',
          name: 'account-invites-list',
          component: () => import('@/views/customerPortalModule/listAccountInvites.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'All Account Invites', active: true },
            ],
            pageTitle: 'All Account Invites',
            requiresAuth: true,
            permissions: ['getAccountInvite'],
          },
        },
        {
          path: '/customerPortal/tickets/list',
          name: 'tickets-list',
          component: () => import('@/views/customerPortalModule/listTickets.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'All Tickets', active: true },
            ],
            pageTitle: 'All Tickets',
            requiresAuth: true,
            permissions: ['getSupportTicket'],
          },
        },

        // =============================================================================
        // Supplier Routes
        // =============================================================================
        {
          path: '/supplierRFQs',
          name: 'Received RFQs',
          component: () => import('@/views/rfqModule/listRFQ/listRFQ.vue'),
          meta: {
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Received RFQs', active: true },
            ],
            pageTitle: 'Received RFQs',
            requiresAuth: true,
            permissions: ['getRfq'],
          },
        },
        {
          path: '/supplierRFQs/:rfqID',
          name: 'SupplierForm',
          component: () => import('@/views/supplierModule/supplierForm.vue'),
          meta: {
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Supplier Form', active: true },
            ],
            pageTitle: 'Supplier Form',
            requiresAuth: true,
            permissions: ['getRfq'],
          },
        },
        // // =============================================================================
        // // Quotes Routes
        // // =============================================================================
        {
          path: 'quotes/all',
          name: 'all-quotes',
          component: () => import('@/views/quoteModule/listQuotes/listQuotes.vue'),
          meta: {
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'All Quotes', active: true },
            ],
            pageTitle: 'All Quotes',
            requiresAuth: true,
            permissions: ['getQuote'],
          },
        },
        {
          path: 'quotes/quote-disposition',
          name: 'quote-disposition',
          component: () => import('@/views/quoteModule/listQuotes/listQuotes.vue'),
          meta: {
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Quote Disposition', active: true },
            ],
            pageTitle: 'Quote Disposition',
            requiresAuth: true,
            permissions: ['getQuote'],
          },
        },
        // =============================================================================
        // User Guide Routes
        // =============================================================================
        {
          path: '/faq/question/',
          name: 'question',
          component: () => import('@/views/helpModule/question.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'FAQ', active: true },
            ],
            pageTitle: 'Frequently Asked Questions',
            permissions: ['default'],
          },
        },
        {
          path: '/faq/question/:id',
          name: 'answer',
          component: () => import('@/views/helpModule/answer.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'FAQ' },
              { title: 'Answer', active: true },
            ],
            pageTitle: 'Answer',
            permissions: ['default'],
          },
        },
        // =============================================================================
        // Engineering Quotient Routes
        // =============================================================================
        {
          path: '/eq/create/',
          name: 'Create EQ',
          component: () => import('@/views/eqModule/createEQ/createEQ.vue'),
          meta: {
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Create EQ', active: true },
            ],
            pageTitle: 'Create EQ',
            permissions: ['createEq'],
          },
        },
        {
          path: '/eq/list/all',
          name: 'list-all-eq',
          component: () => import('@/views/eqModule/listEQ/listEQ.vue'),
          meta: {
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'All EQs', active: true },
            ],
            pageTitle: 'All EQs',
            permissions: ['getEq'],
          },
        },
        {
          path: '/eq/list/active',
          name: 'list-active-eq',
          component: () => import('@/views/eqModule/listEQ/listEQ.vue'),
          meta: {
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Active EQs', active: true },
            ],
            pageTitle: 'Active EQs',
            permissions: ['getEq'],
          },
        },
        {
          path: '/eq/view/:id',
          name: 'view-eq',
          component: () => import('@/views/eqModule/viewEQ/viewEQ2.vue'),
          meta: {
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'View EQ', active: true },
            ],
            pageTitle: 'View EQ',
            permissions: ['getEq'],
          },
        },

        // =============================================================================
        // User Routes
        // =============================================================================
        {
          path: '/users/create/customer',
          name: 'create-customer',
          component: () => import('@/views/userModule/createUser/createUser.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Users' },
              { title: 'Create Customer', active: true },
            ],
            pageTitle: 'Create Customer',
            requiresAuth: true,
            permissions: ['createCustomer'],
          },
        },
        {
          path: '/users/create/customer-contact',
          name: 'create-customer-contact',
          component: () => import('@/views/userModule/createUser/createUser.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Users' },
              { title: 'Create Customer Contact', active: true },
            ],
            pageTitle: 'Create Customer Contact',
            requiresAuth: true,
            permissions: ['createCustomer'],
          },
        },
        {
          path: '/users/create/linkage',
          name: 'create-linkage',
          component: () => import('@/views/userModule/createUser/createUser.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Users' },
              { title: 'Create Linkage User', active: true },
            ],
            pageTitle: 'Create Linkage User',
            requiresAuth: true,
            permissions: ['createLinkage'],
          },
        },
        {
          path: '/users/create/supplier',
          name: 'create-supplier',
          component: () => import('@/views/userModule/createUser/createUser.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Users' },
              { title: 'Create Supplier', active: true },
            ],
            pageTitle: 'Create Supplier',
            requiresAuth: true,
            permissions: ['createSupplier'],
          },
        },
        {
          path: '/users/create/supplier-contact',
          name: 'create-supplier-contact',
          component: () => import('@/views/userModule/createUser/createUser.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Users' },
              { title: 'Create Supplier Contact', active: true },
            ],
            pageTitle: 'Create Supplier Contact',
            requiresAuth: true,
            permissions: ['createSupplier'],
          },
        },
        {
          path: '/users/customer',
          name: 'list-customer',
          component: () => import('@/views/userModule/listUser/UserList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Customers', active: true },
            ],
            pageTitle: 'Customers',
            requiresAuth: true,
            permissions: ['getCustomer'],
          },
        },
        {
          path: '/users/customer-contact',
          name: 'list-customer-contact',
          component: () => import('@/views/userModule/listUser/UserList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Customer Contacts', active: true },
            ],
            pageTitle: 'Customer Contacts',
            requiresAuth: true,
            permissions: ['getCustomer'],
          },
        },
        {
          path: '/users/supplier',
          name: 'list-supplier',
          component: () => import('@/views/userModule/listUser/UserList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Suppliers', active: true },
            ],
            pageTitle: 'Suppliers',
            requiresAuth: true,
            permissions: ['getSupplier'],
          },
        },
        {
          path: '/users/supplier-contact',
          name: 'list-supplier-contact',
          component: () => import('@/views/userModule/listUser/UserList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Supplier Contacts', active: true },
            ],
            pageTitle: 'Supplier Contacts',
            requiresAuth: true,
            permissions: ['getSupplier'],
          },
        },
        {
          path: '/users/linkage',
          name: 'list-linkage',
          component: () => import('@/views/userModule/listUser/UserList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Linkage Users', active: true },
            ],
            pageTitle: 'Linkage Users',
            requiresAuth: true,
            permissions: ['getLinkage'],
          },
        },
        {
          path: '/settings',
          name: 'Usersetting',
          component: () => import('@/views/userModule/user-settings/UserSettings.vue'),
          meta: {
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Settings', active: true },
            ],
            pageTitle: 'Settings',
            requiresAuth: true,
            permissions: ['default'],
          },
        },
        {
          path: '/user/view/:userid',
          name: 'view-user',
          component: () => import('@/views/userModule/viewUser/viewUser.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'User' },
              { title: 'View', active: true },
            ],
            pageTitle: 'View User',
            requiresAuth: true,
            permissions: ['getSupplier', 'getCustomer', 'getLinkage'],
          },
        },
        {
          path: '/user/edit/:userId',
          name: 'edit-user',
          component: () => import('@/views/userModule/user-edit/editUser.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'User' },
              { title: 'Edit', active: true },
            ],
            pageTitle: 'Edit User',
            requiresAuth: true,
            permissions: ['updateSupplier', 'updateCustomer', 'updateLinkage'],
          },
        },
        // =============================================================================
        // Report Routes
        // =============================================================================
        {
          path: '/reports/rfqByValue',
          name: 'rfq-by-value',
          component: () => import('@/views/reportModule/rfqByValue/rfqByValue.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Reports' },
              { title: 'RFQ By Value', active: true },
            ],
            pageTitle: 'RFQ By Value',
            requiresAuth: true,
            permissions: ['default'],
          },
        },
        {
          path: '/reports/rfqByMonth',
          name: 'rfq-by-month',
          component: () => import('@/views/reportModule/rfqByMonth/rfqByMonth.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Reports' },
              { title: 'RFQ By Month', active: true },
            ],
            pageTitle: 'RFQ By Month',
            requiresAuth: true,
            permissions: ['default'],
          },
        },
        {
          path: '/reports/rfqValueWeeklyReport',
          name: 'app-financial-report',
          component: () =>
            import('@/views/reportModule/rfqValueWeeklyReport/rfqValueWeeklyReport.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Reports' },
              { title: 'RFQ Weekly Reports', active: true },
            ],
            pageTitle: 'RFQ Weekly Reports',
            requiresAuth: true,
            permissions: ['getWeeklyReport'],
          },
        },
        {
          path: '/reports/summarySup',
          name: 'app-sup-report',
          component: () => import('@/views/reportModule/summarySup/summarySup.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Reports' },
              { title: 'Supplier', active: true },
            ],
            pageTitle: 'Quote Summary by Supplier',
            requiresAuth: true,
            permissions: ['getSupplierReport'],
          },
        },
        {
          path: '/reports/rfqDetailedReport',
          name: 'app-detailed-report',
          component: () => import('@/views/reportModule/rfqDetailedReport/rfqDetailedReport.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Reports' },
              { title: 'DetailedReport', active: true },
            ],
            pageTitle: 'Detailed Reports',
            requiresAuth: true,
            permissions: ['getDetailedReport'],
          },
        },
        {
          path: '/reports/openOrderReport',
          name: 'open-order-report',
          component: () => import('@/views/reportModule/reports/openOrderReport.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Reports' },
              { title: 'Open Order Report', active: true },
            ],
            pageTitle: 'Open Order Report',
            requiresAuth: true,
            permissions: ['getOpenOrderReport'],
          },
        },
        {
          path: '/reports/shipmentReport',
          name: 'shipment-report',
          component: () => import('@/views/reportModule/reports/shipmentReport.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Reports' },
              { title: 'Shipment Report', active: true },
            ],
            pageTitle: 'Shipment Report',
            requiresAuth: true,
            permissions: ['getShipmentReport'],
          },
        },
        {
          path: '/reports/lateShipmentReport',
          name: 'late-shipment-report',
          component: () => import('@/views/reportModule/reports/lateShipmentReport.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Reports' },
              { title: 'Late Shipment Report', active: true },
            ],
            pageTitle: 'Late Shipment Report',
            requiresAuth: true,
            permissions: ['getOpenOrderReport'],
          },
        },
        {
          path: '/reports/qualificationReport',
          name: 'qualification-orders-report',
          component: () => import('@/views/reportModule/reports/qualificationReport.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Reports' },
              { title: 'Qualification Orders Report', active: true },
            ],
            pageTitle: 'Qualification Orders Report',
            requiresAuth: true,
            permissions: ['getOpenOrderReport'],
          },
        },
        {
          path: '/reports/dailyBookingsReport',
          name: 'daily-bookings-report',
          component: () => import('@/views/reportModule/reports/dailyBookingsReport.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Reports' },
              { title: 'Daily Bookings Report', active: true },
            ],
            pageTitle: 'Daily Bookings Report',
            requiresAuth: true,
            permissions: ['getOpenOrderReport'],
          },
        },
        {
          path: '/reports/dailyShipmentsReport',
          name: 'daily-shipments-report',
          component: () => import('@/views/reportModule/reports/dailyShipmentReports.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Reports' },
              { title: 'Daily Shipments Report', active: true },
            ],
            pageTitle: 'Daily Shipments Report',
            requiresAuth: true,
            permissions: ['getShipmentReport'],
          },
        },
        {
          path: '/customerprofile/list',
          name: 'customerprofilelist',
          component: () => import('@/views/customerProfile/listCP/listCP.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Customer Profile' },
              { title: 'List', active: true },
            ],
            pageTitle: 'List Profiles',
            requiresAuth: true,
            permissions: ['getCustomerProfile'],
          },
        },
        {
          path: '/customerprofile/view/:customerId',
          name: 'view-customer',
          component: () => import('@/views/customerProfile/viewCP.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Customer Profiles', url: '/customerprofile/list' },
              { title: 'View', active: true },
            ],
            pageTitle: 'View Profile',
            requiresAuth: true,
            permissions: ['getCustomerProfile'],
          },
        },
        {
          path: '/customerprofile/create',
          name: 'create-customer-profile',
          component: () => import('@/views/customerProfile/createCP.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Customer Profile' },
              { title: 'List', active: true },
            ],
            pageTitle: 'Create Profile',
            requiresAuth: true,
            permissions: ['createCustomerProfile'],
          },
        },
        {
          path: '/customerprofile/edit/:customerId',
          name: 'customerprofileedit',
          component: () => import('@/views/customerProfile/editCP.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Customer Profile' },
              { title: 'Edit', active: true },
            ],
            pageTitle: 'Edit Profile',
            requiresAuth: true,
            permissions: ['updateCustomerProfile'],
          },
        },
        // =============================================================================
        // Order Routes
        // =============================================================================
        {
          path: '/sales-order/create',
          name: 'create-sales-order',
          component: () => import('@/views/orderModule/salesOrder/createSalesOrder.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Order' },
              { title: 'Create Order', active: true },
            ],
            pageTitle: 'Create Sales Order',
            requiresAuth: true,
            permissions: ['createSalesOrder'],
          },
        },
        {
          path: '/customer-po/list',
          name: 'list-customer-po',
          component: () => import('@/views/orderModule/salesOrder/listCustomerPo.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Order' },
              { title: "List Customer PO's", active: true },
            ],
            pageTitle: "List Customer PO's",
            requiresAuth: true,
            permissions: ['getSalesOrder'],
          },
        },
        {
          path: '/customer-po/:id',
          name: 'edit-customer-po',
          component: () => import('@/views/orderModule/salesOrder/listSalesOrder.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Order' },
              { title: "Customer Po's" },
              { title: "List Sales Order's", active: true },
            ],
            pageTitle: "List Sales Order's",
            requiresAuth: true,
            permissions: ['getSalesOrder'],
          },
        },
        {
          path: '/sales-order/list',
          name: 'list-all-sales-order',
          component: () => import('@/views/orderModule/salesOrder/listSalesOrder.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Order' },
              { title: "All Sales Order's", active: true },
            ],
            pageTitle: "All Sales Order's",
            requiresAuth: true,
            permissions: ['getSalesOrder'],
          },
        },
        {
          path: '/sales-order/:id',
          name: 'edit-sales-order',
          component: () => import('@/views/orderModule/salesOrder/editSalesOrder.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Order' },
              { title: 'Edit Sales Order', active: true },
            ],
            pageTitle: 'Edit Sales Order',
            requiresAuth: true,
            permissions: ['updateSalesOrder'],
          },
        },
        {
          path: '/purchase-order/create',
          name: 'create-purchase-order',
          component: () => import('@/views/orderModule/purchaseOrder/createPurchaseOrder.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Order' },
              { title: 'Create Purchase Order', active: true },
            ],
            pageTitle: 'Create Purchase Order',
            requiresAuth: true,
            permissions: ['createSupplierPO'],
          },
        },
        {
          path: '/purchase-order/list',
          name: 'list-purchase-order',
          component: () => import('@/views/orderModule/purchaseOrder/listPurchaseOrder.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Order' },
              { title: 'List Purchase Order', active: true },
            ],
            pageTitle: 'List Purchase Order',
            requiresAuth: true,
            permissions: ['getSupplierPO'],
          },
        },
        {
          path: '/purchase-order/edit/:id',
          name: 'edit-purchase-order',
          component: () =>
            import('@/views/orderModule/purchaseOrder/editSupplierPurchaseOrder.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Order' },
              { title: 'Edit Purchase Order', active: true },
            ],
            pageTitle: 'Edit Purchase Order',
            requiresAuth: true,
            permissions: ['updateSupplierPO'],
          },
        },
        //   {
        // 	path: '/order/list',
        // 	name: 'list-order',
        // 	component: () => import('@/views/orderModule/listSalesOrder/listSalesOrder.vue'),
        // 	meta: {
        // 		breadcrumb: [
        // 		{title: 'Home', url: '/'},
        // 		{ title: 'Order' },
        // 		{title: 'List Order', active: true},
        // 		],
        // 		pageTitle: 'List Sales Order',
        // 		requiresAuth: true,
        // 		permissions : ["default"]
        // 	},
        // },
        // =============================================================================
        // Shipment Routes
        // =============================================================================
        {
          path: '/shipment/create',
          name: 'create-shipment',
          component: () => import('@/views/shipmentModule/createShipment/createShipment.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Shipment' },
              { title: 'Create Shipment', active: true },
            ],
            pageTitle: 'Create Shipment',
            requiresAuth: true,
            permissions: ['createShipment'],
          },
        },
        {
          path: '/shipment/list',
          name: 'list-shipment',
          component: () => import('@/views/shipmentModule/listShipment/listShipment.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Shipment' },
              { title: 'List Shipment', active: true },
            ],
            pageTitle: 'List Shipment',
            requiresAuth: true,
            permissions: ['getShipment'],
          },
        },
        {
          path: '/shipment/edit/:id',
          name: 'edit-shipment',
          component: () => import('@/views/shipmentModule/editShipment/editShipment.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Shipment' },
              { title: 'Edit Shipment', active: true },
            ],
            pageTitle: 'Edit Shipment',
            requiresAuth: true,
            permissions: ['updateShipment'],
          },
        },
        // =============================================================================
        // Wip Routes
        // =============================================================================
        {
          path: '/wip-status/create',
          name: 'create-wip-status',
          component: () => import('@/views/wipStatusModule/createWipStatus/createWipStatus.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'WIP Status' },
              { title: 'Create WIP Status', active: true },
            ],
            pageTitle: 'Create WIP Status',
            requiresAuth: true,
            permissions: ['createWip'],
          },
        },
        {
          path: '/wip-status/report',
          name: 'wip-status-report',
          component: () => import('@/views/wipStatusModule/wipStatusReport/wipStatusReport.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'WIP Status' },
              { title: 'WIP Status Report', active: true },
            ],
            pageTitle: 'WIP Status Report',
            requiresAuth: true,
            permissions: ['getWip'],
          },
        },
        {
          path: '/supplier-fgi/create',
          name: 'create-supplier-fgi',
          component: () => import('@/views/wipStatusModule/createFgi/createSupplierFgi.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Supplier FGI' },
              { title: 'Create Supplier FGI', active: true },
            ],
            pageTitle: 'Create Supplier Fgi',
            requiresAuth: true,
            permissions: ['createSupplierFgi'],
          },
        },
        {
          path: '/supplier-fgi/report',
          name: 'supplier-fgi-report',
          component: () => import('@/views/wipStatusModule/createFgi/listSupplierFgi.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Supplier FGI' },
              { title: 'Supplier FGI Report', active: true },
            ],
            pageTitle: 'Supplier Fgi Report',
            requiresAuth: true,
            permissions: ['getSupplierFgi'],
          },
        },
        {
          path: '/supplier-fgi/pending',
          name: 'supplier-fgi-pending',
          component: () => import('@/views/wipStatusModule/createFgi/listSupplierFgi.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Supplier FGI' },
              { title: 'Pending Supplier FGI', active: true },
            ],
            pageTitle: 'Pending Supplier Fgi',
            requiresAuth: true,
            permissions: ['getSupplierFgi'],
          },
        },
        {
          path: '/supplier-fgi/:id',
          name: 'supplier-fgi-edit',
          component: () => import('@/views/wipStatusModule/createFgi/editSupplierFgi.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Supplier FGI' },
              { title: 'Edit Supplier FGI', active: true },
            ],
            pageTitle: 'Supplier Fgi Edit',
            requiresAuth: true,
            permissions: ['updateSupplierFgi'],
          },
        },
        {
          path: '/linkage-fgi/create',
          name: 'create-linkage-fgi',
          component: () => import('@/views/wipStatusModule/createFgi/createLinkageFgi.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Linkage FGI' },
              { title: 'Create Linkage FGI', active: true },
            ],
            pageTitle: 'Create Linkage Fgi',
            requiresAuth: true,
            permissions: ['createLinkageFgi'],
          },
        },
        {
          path: '/linkage-fgi/report',
          name: 'linkage-fgi-report',
          component: () => import('@/views/wipStatusModule/createFgi/listLinkageFgi.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Linkage FGI' },
              { title: 'Linkage FGI Report', active: true },
            ],
            pageTitle: 'Linkage Fgi Report',
            requiresAuth: true,
            permissions: ['getLinkageFgi'],
          },
        },
        {
          path: '/linkage-fgi/:id',
          name: 'linkage-fgi-edit',
          component: () => import('@/views/wipStatusModule/createFgi/editLinkageFgi.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Linkage FGI' },
              { title: 'Edit Linkage FGI', active: true },
            ],
            pageTitle: 'Linkage Fgi Edit',
            requiresAuth: true,
            permissions: ['updateLinkageFgi'],
          },
        },
        // =============================================================================
        // Part Review
        // =============================================================================
        {
          path: '/part/review',
          name: 'part-review',
          component: () => import('@/views/partReviewModule/reviewPart.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Part' },
              { title: 'review', active: true },
            ],
            pageTitle: 'Part Review',
            requiresAuth: true,
            permissions: ['default'],
          },
        },
        // =============================================================================
        // Invoice Module
        // =============================================================================
        {
          path: '/invoice/pending-invoice-creation',
          name: 'pending-invoice-creation',
          component: () =>
            import('@/views/invoiceModule/pendingInvoiceCreation/pendingInvoiceCreation.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Invoice' },
              { title: 'Pending Invoice Creation', active: true },
            ],
            pageTitle: 'Pending Invoice Creation',
            requiresAuth: true,
            permissions: ['default'],
          },
        },
        // =============================================================================
        // Master Data Routes
        // =============================================================================
        {
          path: '/email/list',
          name: 'email-list',
          component: () => import('@/views/masterDataModule/email/userEmailList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Email List', active: true },
            ],
            pageTitle: 'Edit Email List',
            requiresAuth: true,
            permissions: ['manageEmailList'],
          },
        },
        {
          path: '/build-site/list',
          name: 'list-buildSites',
          component: () => import('@/views/masterDataModule/buildSite/listSite.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Build Sites', active: true },
            ],
            pageTitle: 'Build Sites',
            requiresAuth: true,
            permissions: ['getBuildSite'],
          },
        },

        {
          path: '/part',
          name: 'list-part',
          component: () => import('@/views/masterDataModule/part/listPart.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Part' },
              { title: 'List', active: true },
            ],
            pageTitle: 'List Part',
            requiresAuth: true,
            permissions: ['getPart'],
          },
        },

        {
          path: '/part/create',
          name: 'create-part',
          component: () => import('@/views/masterDataModule/part/createPart.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Part' },
              { title: 'Create', active: true },
            ],
            pageTitle: 'Create Part',
            requiresAuth: true,
            permissions: ['createPart'],
          },
        },
        {
          path: '/part/edit/:partId',
          name: 'edit-part',
          component: () => import('@/views/masterDataModule/part/editPart.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Part' },
              { title: 'Edit', active: true },
            ],
            pageTitle: 'Edit Part',
            requiresAuth: true,
            permissions: ['updatePart'],
          },
        },
        {
          path: '/part/duplicate/:partId',
          name: 'duplicate-part',
          component: () => import('@/views/masterDataModule/part/duplicatePart.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Part' },
              { title: 'Duplicate', active: true },
            ],
            pageTitle: 'Duplicate Part',
            requiresAuth: true,
            permissions: ['createPart'],
          },
        },
        {
          path: '/part/view/:partId',
          name: 'view-part',
          component: () => import('@/views/masterDataModule/part/viewPart.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Part' },
              { title: 'View', active: true },
            ],
            pageTitle: 'View Part',
            requiresAuth: true,
            permissions: ['getPart'],
          },
        },
        {
          path: '/oem/list',
          name: 'list-oem',
          component: () => import('@/views/masterDataModule/oem/listOem.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'OEM' },
              { title: 'List', active: true },
            ],
            pageTitle: 'List OEM',
            requiresAuth: true,
            permissions: ['default'],
          },
        },
        {
          path: '/warehouse',
          name: 'list-warehouse',
          component: () => import('@/views/masterDataModule/warehouse/listWarehouse.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Warehouse' },
              { title: 'List', active: true },
            ],
            pageTitle: 'List Warehouse',
            requiresAuth: true,
            permissions: ['getWarehouse'],
          },
        },
        {
          path: '/warehouse/create',
          name: 'create-warehouse',
          component: () => import('@/views/masterDataModule/warehouse/createWarehouse.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Warehouse' },
              { title: 'Create', active: true },
            ],
            pageTitle: 'Create Warehouse',
            requiresAuth: true,
            permissions: ['createWarehouse'],
          },
        },
        {
          path: '/warehouse/inventory',
          name: 'warehouse-inventory',
          component: () => import('@/views/warehouseModule/warehouseInventory.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Warehouse' },
              { title: 'Inventory', active: true },
            ],
            pageTitle: 'Warehouse Inventory',
            requiresAuth: true,
            permissions: ['getWarehouseInventory'],
          },
        },
        {
          path: '/warehouse/ledger',
          name: 'warehouse-ledger',
          component: () => import('@/views/warehouseModule/warehouseLedger.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Warehouse' },
              { title: 'Ledger', active: true },
            ],
            pageTitle: 'Warehouse Ledger',
            requiresAuth: true,
            permissions: ['getWarehouseLedger'],
          },
        },
        {
          path: '/tax',
          name: 'list-tax',
          component: () => import('@/views/masterDataModule/tax/listTax.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Tax' },
              { title: 'List', active: true },
            ],
            pageTitle: 'List Tax',
            requiresAuth: true,
            permissions: ['default'],
          },
        },
        {
          path: '/payment-term',
          name: 'list-payment-term',
          component: () => import('@/views/masterDataModule/paymentTerm/listPaymentTerm.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Payment Term' },
              { title: 'List', active: true },
            ],
            pageTitle: 'List Payment Terms',
            requiresAuth: true,
            permissions: ['getPaymentTerms'],
          },
        },
        {
          path: '/inco-term',
          name: 'list-inco-term',
          component: () => import('@/views/masterDataModule/INCOTerm/listINCOTerm.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'INCO Term' },
              { title: 'List', active: true },
            ],
            pageTitle: 'List INCO Terms',
            requiresAuth: true,
            permissions: ['getIncoTerms'],
          },
        },
        {
          path: '/wip-location',
          name: 'list-wip-location',
          component: () => import('@/views/masterDataModule/WIPLocation/listWIPLocation.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'WIP Location' },
              { title: 'List', active: true },
            ],
            pageTitle: 'List WIP Locations',
            requiresAuth: true,
            permissions: ['getWipLocation'],
          },
        },
        {
          path: '/sales-order-type',
          name: 'list-sales-order-type',
          component: () => import('@/views/masterDataModule/salesOrderType/listSalesOrderType.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Sales Order Type' },
              { title: 'List', active: true },
            ],
            pageTitle: 'List Sales Order Type',
            requiresAuth: true,
            permissions: ['getSalesOrderType'],
          },
        },
        {
          path: '/lineItem-type',
          name: 'lineItem-type',
          component: () => import('@/views/masterDataModule/lineItemType/lineItemType.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'LineItem Type' },
              { title: 'List', active: true },
            ],
            pageTitle: 'Line Item Type',
            requiresAuth: true,
            permissions: ['default'],
          },
        },
        {
          path: '/freight-carrier',
          name: 'list-freight-carrier',
          component: () => import('@/views/masterDataModule/freightCarrier/listFreightCarrier.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Freight Carrier' },
              { title: 'List', active: true },
            ],
            pageTitle: 'List Freight Carriers',
            requiresAuth: true,
            permissions: ['getFreightCarrier'],
          },
        },
        {
          path: '/sales-rep',
          name: 'list-sales-rep',
          component: () => import('@/views/masterDataModule/salesRep/listSalesRep.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Sales Rep' },
              { title: 'List', active: true },
            ],
            pageTitle: 'List Sales Reps',
            requiresAuth: true,
            permissions: ['getSalesRep'],
          },
        },
        {
          path: '/sales-rep-company',
          name: 'list-sales-rep-company',
          component: () =>
            import('@/views/masterDataModule/salesRepCompany/listSalesRepCompany.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Sales Rep Company' },
              { title: 'List', active: true },
            ],
            pageTitle: 'List Sales Rep Companys',
            requiresAuth: true,
            permissions: ['getSalesRepCompany'],
          },
        },
        {
          path: '/warehouse-location',
          name: 'list-warehouse-location',
          component: () =>
            import('@/views/masterDataModule/warehouseLocation/listWarehouseLocation.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Warehouse Location' },
              { title: 'List', active: true },
            ],
            pageTitle: 'List Warehouse Locations',
            requiresAuth: true,
            permissions: ['getWipLocation'],
          },
        },
        // =============================================================================
        // INVOICE ROUTES
        // =============================================================================
        {
          path: '/invoice/customer/create',
          name: 'create-customer-invoice',
          component: () => import('@/views/invoiceModule/createInvoice/Create.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Invoice' },
              { title: 'Create Customer Invoice', active: true },
            ],
            pageTitle: 'Create Customer Invoice',
            requiresAuth: true,
            permissions: ['createCustomerInvoice'],
          },
        },
        {
          path: '/invoice/customer/create/:customerId/:shipmentId',
          name: 'create-customer-invoice-from-shipment',
          component: () => import('@/views/invoiceModule/createInvoice/Create.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Invoice' },
              { title: 'Create Customer Invoice', active: true },
            ],
            pageTitle: 'Create Customer Invoice',
            requiresAuth: true,
            permissions: ['createCustomerInvoice'],
          },
        },
        {
          path: '/invoice/customer/list',
          name: 'list-invoice',
          component: () =>
            import('@/views/invoiceModule/listCustomerInvoice/listCustomerInvoice.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Invoice' },
              { title: 'List Customer Invoice', active: true },
            ],
            pageTitle: 'List Customer Invoice',
            requiresAuth: true,
            permissions: ['getCustomerInvoice'],
          },
        },
        {
          path: '/invoice/customer/edit/:id',
          name: 'edit-customer-invoice',
          component: () => import('@/views/invoiceModule/createInvoice/editCustomerInvoice.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Invoice' },
              { title: 'Edit Customer Invoice', active: true },
            ],
            pageTitle: 'Edit Customer Invoice',
            requiresAuth: true,
            permissions: ['updateCustomerInvoice'],
          },
        },
        {
          path: '/invoice/customer/view/:id',
          name: 'view-invoice',
          component: () => import('@/views/invoiceModule/createInvoice/ViewEditInvoice.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Invoice' },
              { title: 'View Customer Invoice', active: true },
            ],
            pageTitle: 'View Customer Invoice',
            requiresAuth: true,
            permissions: ['updateCustomerInvoice'],
          },
        },
        {
          path: '/invoice/supplier/pending/list',
          name: 'list-pending-supplier-invoice',
          component: () =>
            import(
              '@/views/invoiceModule/supplierInvoice/pendingSupplierInvoice/pendingSupplierInvoice.vue'
            ),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Invoice' },
              { title: 'Pending Supplier Invoice', active: true },
            ],
            pageTitle: 'Pending Supplier Invoice',
            requiresAuth: true,
            permissions: ['createSupplierInvoice'],
          },
        },
        {
          path: '/invoice/supplier/create',
          name: 'create-supplier-invoice',
          component: () =>
            import('@/views/invoiceModule/supplierInvoice/createSupplierInvoice.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Invoice' },
              { title: 'Create Supplier Invoice', active: true },
            ],
            pageTitle: 'Create Supplier Invoice',
            requiresAuth: true,
            permissions: ['createSupplierInvoice'],
          },
        },
        {
          path: '/invoice/supplier/create/:fgiId',
          name: 'create-supplier-invoice-from-fgi',
          component: () =>
            import('@/views/invoiceModule/supplierInvoice/createSupplierInvoice.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Invoice' },
              { title: 'Create Supplier Invoice', active: true },
            ],
            pageTitle: 'Create Supplier Invoice',
            requiresAuth: true,
            permissions: ['createSupplierInvoice'],
          },
        },
        {
          path: '/invoice/supplier/edit/:id',
          name: 'edit-supplier-invoice',
          component: () => import('@/views/invoiceModule/supplierInvoice/editSupplierInvoice.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Invoice' },
              { title: 'Edit Supplier Invoice', active: true },
            ],
            pageTitle: 'Edit Supplier Invoice',
            requiresAuth: true,
            permissions: ['updateSupplierInvoice'],
          },
        },

        {
          path: '/invoice/supplier/list',
          name: 'list-supplier-invoice',
          component: () => import('@/views/invoiceModule/supplierInvoice/listSupplierInvoice.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Invoice' },
              { title: 'List Supplier Invoice', active: true },
            ],
            pageTitle: 'List Supplier Invoice',
            requiresAuth: true,
            permissions: ['getSupplierInvoice'],
          },
        },
        // =============================================================================
        // RMA MODULE ROUTES
        // =============================================================================
        {
          path: '/rma/customerCareIncident/create',
          name: 'create-customer-care-incident',
          component: () =>
            import('@/views/rmaModule/customerCareIncident/createCustomerCareIncident.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'RMA' },
              { title: 'Customer Care Incident' },
              { title: 'Create Customer Care Incident', active: true },
            ],
            pageTitle: 'Create Customer Care Incident',
            requiresAuth: true,
            permissions: ['createCustomerCareIncident'],
          },
        },
        {
          path: '/rma/customerCareIncident/list',
          name: 'list-customer-care-incident',
          component: () =>
            import('@/views/rmaModule/customerCareIncident/listCustomerCareIncident.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'RMA' },
              { title: 'Customer Care Incident' },
              { title: 'List Customer Care Incident', active: true },
            ],
            pageTitle: 'List Customer Care Incident',
            requiresAuth: true,
            permissions: ['getCustomerCareIncident'],
          },
        },
        {
          path: '/rma/customerCareIncident/edit/:id',
          name: 'edit-customer-care-incident',
          component: () =>
            import('@/views/rmaModule/customerCareIncident/editCustomerCareIncident.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'RMA' },
              { title: 'Customer Care Incident' },
              { title: 'Edit Customer Care Incident', active: true },
            ],
            pageTitle: 'Edit Customer Care Incident',
            requiresAuth: true,
            permissions: ['updateCustomerCareIncident'],
          },
        },
        {
          path: '/rma/list',
          name: 'list-rma',
          component: () => import('@/views/rmaModule/allRmas.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'RMA' },
              { title: 'List RMA', active: true },
            ],
            pageTitle: 'List RMA',
            requiresAuth: true,
            permissions: ['getRma'],
          },
        },
        {
          path: '/rma/creditRequest/create',
          name: 'create-credit-request',
          component: () => import('@/views/rmaModule/creditOrDebitRequest/createCreditRequest.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'RMA' },
              { title: 'Credit Request' },
              { title: 'Create Credit Request', active: true },
            ],
            pageTitle: 'Create Credit Request',
            requiresAuth: true,
            permissions: ['createCreditRequest'],
          },
        },
        {
          path: '/rma/creditRequest/edit/:id',
          name: 'edit-credit-request',
          component: () => import('@/views/rmaModule/creditOrDebitRequest/editCreditRequest.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'RMA' },
              { title: 'Credit Request' },
              { title: 'Edit Credit Request', active: true },
            ],
            pageTitle: 'Edit Credit Request',
            requiresAuth: true,
            permissions: ['updateCreditRequest'],
          },
        },
        {
          path: '/rma/creditRequest/list',
          name: 'list-credit-request',
          component: () => import('@/views/rmaModule/creditOrDebitRequest/listCreditRequest.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'RMA' },
              { title: 'Credit Request' },
              { title: 'List Credit Request', active: true },
            ],
            pageTitle: 'List Credit Request',
            requiresAuth: true,
            permissions: ['getCreditRequest'],
          },
        },
        {
          path: '/rma/creditRequest/create/:id',
          name: 'create-credit-request-from-rma',
          component: () => import('@/views/rmaModule/creditOrDebitRequest/createCreditRequest.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'RMA' },
              { title: 'Credit Request' },
              { title: 'Create Credit Request', active: true },
            ],
            pageTitle: 'Create Credit Request',
            requiresAuth: true,
            permissions: ['createCreditRequest'],
          },
        },
        {
          path: '/rma/debitRequest/create',
          name: 'create-debit-request',
          component: () => import('@/views/rmaModule/creditOrDebitRequest/createDebitRequest.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'RMA' },
              { title: 'Debit Request' },
              { title: 'Create Debit Request', active: true },
            ],
            pageTitle: 'Create Debit Request',
            requiresAuth: true,
            permissions: ['createDebitRequest'],
          },
        },
        {
          path: '/rma/debitRequest/edit/:id',
          name: 'edit-debit-request',
          component: () => import('@/views/rmaModule/creditOrDebitRequest/editDebitRequest.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'RMA' },
              { title: 'Debit Request' },
              { title: 'Edit Debit Request', active: true },
            ],
            pageTitle: 'Edit Debit Request',
            requiresAuth: true,
            permissions: ['updateDebitRequest'],
          },
        },
        {
          path: '/rma/debitRequest/list',
          name: 'list-debit-request',
          component: () => import('@/views/rmaModule/creditOrDebitRequest/listDebitRequest.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'RMA' },
              { title: 'Debit Request' },
              { title: 'List Debit Request', active: true },
            ],
            pageTitle: 'List Debit Request',
            requiresAuth: true,
            permissions: ['getDebitRequest'],
          },
        },
        {
          path: '/rma/debitRequest/create/:id',
          name: 'create-debit-request-from-rma',
          component: () => import('@/views/rmaModule/creditOrDebitRequest/createDebitRequest.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'RMA' },
              { title: 'Debit Request' },
              { title: 'Create Debit Request', active: true },
            ],
            pageTitle: 'Create Debit Request',
            requiresAuth: true,
            permissions: ['createDebitRequest'],
          },
        },
        {
          path: '/rma/pendingApproval/creditRequest/list',
          name: 'list-credit-pending-approval',
          component: () =>
            import('@/views/rmaModule/pendingApproval/listCreditPendingApproval.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'RMA' },
              { title: 'List Credit Request Pending Approval', active: true },
            ],
            pageTitle: 'List Credit Request Pending Approval',
            requiresAuth: true,
            permissions: ['getCreditRequest'],
          },
        },
        {
          path: '/rma/pendingApproval/debitRequest/list',
          name: 'list-debit-pending-approval',
          component: () => import('@/views/rmaModule/pendingApproval/listDebitPendingApproval.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'RMA' },
              { title: 'List Debit Request Pending Approval', active: true },
            ],
            pageTitle: 'List Debit Request Pending Approval',
            requiresAuth: true,
            permissions: ['getDebitRequest'],
          },
        },
        {
          path: '/rma/credit/approved/list',
          name: 'list-approved-credit',
          component: () => import('@/views/rmaModule/approvedRma/creditApproved.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'RMA' },
              { title: 'Approved Credit List', active: true },
            ],
            pageTitle: 'Approved Credit List',
            requiresAuth: true,
            permissions: ['getCreditRequest'],
          },
        },
        {
          path: '/rma/debit/approved/list',
          name: 'list-approved-debit',
          component: () => import('@/views/rmaModule/approvedRma/debitApproved.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'RMA' },
              { title: 'Approved Debit List', active: true },
            ],
            pageTitle: 'Approved Debit List',
            requiresAuth: true,
            permissions: ['getDebitRequest'],
          },
        },
        // =============================================================================
        // CREDIT MEMO ROUTES
        // =============================================================================
        {
          path: '/creditMemo/create',
          name: 'create-creditMemo',
          component: () => import('@/views/creditMemo/createCreditMemo.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Credit Memo' },
              { title: 'Create Credit Memo', active: true },
            ],
            pageTitle: 'Create Credit Memo',
            requiresAuth: true,
            permissions: ['createCreditMemo'],
          },
        },
        {
          path: '/creditMemo/list',
          name: 'list-creditMemo',
          component: () => import('@/views/creditMemo/listCreditMemo.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Credit Memo' },
              { title: 'List Credit Memo', active: true },
            ],
            pageTitle: 'List Credit Memo',
            requiresAuth: true,
            permissions: ['getCreditMemo'],
          },
        },
        {
          path: '/creditMemo/edit/:id',
          name: 'edit-creditMemo',
          component: () => import('@/views/creditMemo/editCreditMemo.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Credit Memo' },
              { title: 'Edit Credit Memo', active: true },
            ],
            pageTitle: 'Edit Credit Memo',
            requiresAuth: true,
            permissions: ['updateCreditMemo'],
          },
        },
        // =============================================================================
        // DEBIT MEMO ROUTES
        // =============================================================================
        {
          path: '/debit-memo/create',
          name: 'create-debit-memo',
          component: () => import('@/views/debitMemo/createDebitMemo.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Debit Memo' },
              { title: 'Create Debit Memo', active: true },
            ],
            pageTitle: 'Create Debit Memo',
            requiresAuth: true,
            permissions: ['createDebitMemo'],
          },
        },
        {
          path: '/debit-memo/list',
          name: 'list-debit-memo',
          component: () => import('@/views/debitMemo/listDebitMemo.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Debit Memo' },
              { title: 'List Debit Memo', active: true },
            ],
            pageTitle: 'List Debit Memo',
            requiresAuth: true,
            permissions: ['getDebitMemo'],
          },
        },
        {
          path: '/debit-memo/edit/:id',
          name: 'edit-debit-memo',
          component: () => import('@/views/debitMemo/editDebitMemo.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Debit Memo' },
              { title: 'Edit Debit Memo', active: true },
            ],
            pageTitle: 'Edit Debit Memo',
            requiresAuth: true,
            permissions: ['updateDebitMemo'],
          },
        },
        // =============================================================================
        // QUALITY MODULE ROUTES
        // =============================================================================
        {
          path: '/quality/quality-certification-report',
          name: 'quality-certification-report',
          component: () => import('@/views/qualityModule/qualityCertificationReport.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Quality' },
              { title: 'Quality Certification Report', active: true },
            ],
            pageTitle: 'Quality Certification Report',
            requiresAuth: true,
            permissions: ['createQCR'],
          },
        },
        {
          path: '/quality/quality-certification-report/pending',
          name: 'quality-certification-pending-list',
          component: () => import('@/views/qualityModule/listQualityCertificationReport.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Quality' },
              { title: 'Pending Quality Certification', active: true },
            ],
            pageTitle: 'Pending Quality Certification',
            requiresAuth: true,
            permissions: ['getQCR'],
          },
        },
        {
          path: '/quality/quality-certification-report/list',
          name: 'quality-certification-report-list',
          component: () => import('@/views/qualityModule/listQualityCertificationReport.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Quality' },
              { title: 'List Quality Certification Report', active: true },
            ],
            pageTitle: 'List Quality Certification Report',
            requiresAuth: true,
            permissions: ['getQCR'],
          },
        },
        {
          path: '/quality/quality-certification-report/:id',
          name: 'edit-quality-certification-report',
          component: () => import('@/views/qualityModule/editQualityCertificationReport.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Quality' },
              { title: 'Edit Quality Certification Report', active: true },
            ],
            pageTitle: 'Edit Quality Certification Report',
            requiresAuth: true,
            permissions: ['updateQCR'],
          },
        },
        // =============================================================================
        // QUALITY OBJECTIVES MODULE ROUTES
        // =============================================================================
        {
          path: '/quality/objectives/revenue-and-gross-profit',
          name: 'revenue-and-gross-profit',
          component: () => import('@/views/qualityObjectives/revenueAndGrossProfit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Quality Objectives' },
              { title: 'Revenue And Gross Profit', active: true },
            ],
            pageTitle: 'Revenue And Gross Profit',
            requiresAuth: true,
            permissions: ['createQualityObjectives'],
          },
        },
        {
          path: '/quality/objectives/customer-satisfaction',
          name: 'customer-satisfaction',
          component: () => import('@/views/qualityObjectives/customerSatisfaction.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Quality Objectives' },
              { title: 'Customer Satisfaction', active: true },
            ],
            pageTitle: 'Customer Satisfaction',
            requiresAuth: true,
            permissions: ['createQualityObjectives'],
          },
        },
        {
          path: '/quality/objectives/on-time-delivery',
          name: 'on-time-delivery',
          component: () => import('@/views/qualityObjectives/onTimeDelivery.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Quality Objectives' },
              { title: 'On Time Delivery', active: true },
            ],
            pageTitle: 'On Time Delivery',
            requiresAuth: true,
            permissions: ['createQualityObjectives'],
          },
        },
        {
          path: '/quality/objectives/rma',
          name: 'rma',
          component: () => import('@/views/qualityObjectives/rma.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Quality Objectives' },
              { title: 'RMA', active: true },
            ],
            pageTitle: 'RMA',
            requiresAuth: true,
            permissions: ['createQualityObjectives'],
          },
        },
        {
          path: '/quality/objectives/quality-objectives-report',
          name: 'quality-objectives-report',
          component: () => import('@/views/qualityObjectives/qualityObjectivesReport.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Quality Objectives' },
              { title: 'Quality Objectives Report', active: true },
            ],
            pageTitle: 'Quality Objectives Report',
            requiresAuth: true,
            permissions: ['getQualityObjectives'],
          },
        },
        {
          path: '/quality/objectives/quality-objectives-list',
          name: 'quality-objectives-list',
          component: () => import('@/views/qualityObjectives/qualityObjectivesList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Quality Objectives' },
              { title: 'List Quality Objectives', active: true },
            ],
            pageTitle: 'List Quality Objectives',
            requiresAuth: true,
            permissions: ['getQualityObjectives'],
          },
        },
        // =============================================================================
        // Deals Module Routes
        // =============================================================================
        {
          path: '/deals',
          name: 'deals',
          component: () => import('@/views/dealsModule/listDeals.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Deals', active: true },
            ],
            pageTitle: 'Deals',
            requiresAuth: true,
            permissions: ['getDeal'],
          },
        },
        {
          path: '/deals/prospects',
          name: 'prospects',
          component: () => import('@/views/masterDataModule/prospects/prospects.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Prospects', active: true },
            ],
            pageTitle: 'Prospects',
            requiresAuth: true,
            permissions: ['getDeal'],
          },
        },
        ...ToolsModuleRoutes,
      ],
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '/login',
      name: 'page-login',
      component: () => import('@/views/authenticationModule/Login.vue'),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/forgotPassword',
      name: 'page-resetPassword',
      component: () => import('@/views/authenticationModule/ForgotPassword.vue'),
      meta: {
        requiresAuth: false,
      },
    },

    // Redirect to 404 page, if no match found
    {
      path: '*',
      name: 'page-error-404',
      component: () => import('@/views/authenticationModule/Error404.vue'),
      meta: {
        requiresAuth: false,
      },
    },
  ],
})
// let token = localStorage.getItem('token')

// let permissions = null
// let havePermission = false
// if(token)
//   this.$axios.get("getCurrentUserPermissions/"+token).then(response=>{
//     permissions = response.data
//   }).catch(error=>{
//     console.dir(error)
//   })

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token')
  let permissions = store.getters.currentUserPermissions

  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)

  if (!requiresAuth) {
    next()
  } else if (!token) {
    router.replace('/login')
  } else {
    const permRequired = to.matched[1].meta.permissions
    let havePermission = false
    if (permissions) {
      havePermission = false
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      permRequired.forEach((perm: any) => {
        if (permissions.includes(perm)) {
          havePermission = true
          next()
        }
      })
      if (!havePermission) router.replace(from.path)
    } else {
      const $http = axios.create({
        baseURL: process.env.VUE_APP_BACKEND_URL,
        // baseURL: 'http://192.168.29.212:5000/api/v1/',
      })
      $http
        .get(`getCurrentUserPermissions/${token}`, {
          params: {
            token,
          },
        })
        .then((response) => {
          permissions = response.data

          permissions.push('default')
          havePermission = false
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          permRequired.forEach((perm: any) => {
            if (permissions.includes(perm)) {
              havePermission = true
              next()
            }
          })
          if (!havePermission) router.replace(from.path)
        })
        .catch((error) => {
          console.log(error, 'KJFDLKDJLKSFJ')
          localStorage.clear()
          router.replace('/login')
          console.dir(error)
        })
    }
  }
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router

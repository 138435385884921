/*= ========================================================================================
  File Name: actions.js
  Description: Vuex Store - actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== */
import $axios from '@/axios-instance'

const actions = {
  // /////////////////////////////////////////////
  // COMPONENTS
  // /////////////////////////////////////////////

  // Vertical NavMenu
  updateVerticalNavMenuWidth({ commit }, width) {
    commit('UPDATE_VERTICAL_NAV_MENU_WIDTH', width)
  },

  // VxAutoSuggest
  updateStarredPage({ commit }, payload) {
    commit('UPDATE_STARRED_PAGE', payload)
  },

  // The Navbar
  arrangeStarredPagesLimited({ commit }, list) {
    commit('ARRANGE_STARRED_PAGES_LIMITED', list)
  },
  arrangeStarredPagesMore({ commit }, list) {
    commit('ARRANGE_STARRED_PAGES_MORE', list)
  },

  // /////////////////////////////////////////////
  // UI
  // /////////////////////////////////////////////
  setContentOverlay({ commit }, val) {
    commit('SET_CONTENT_OVERLAY', val)
  },
  toggleContentOverlay({ commit }) {
    commit('TOGGLE_CONTENT_OVERLAY')
  },
  updateTheme({ commit }, val) {
    commit('UPDATE_THEME', val)
  },

  // /////////////////////////////////////////////
  // User/Account
  // /////////////////////////////////////////////

  fetchUserData({ commit }) {
    const token = localStorage.getItem('token')

    if (token) {
      $axios
        .get(`getCurrentUser/${token}`)
        .then((response) => {
          commit('UPDATE_USER_INFO', response.data)
        })
        .catch((error) => {
          console.dir(error)
        })
    }
  },
  fetchUserPermissions({ commit }) {
    const token = localStorage.getItem('token')
    if (token) {
      $axios
        .get(`getCurrentUserPermissions/${token}`)
        .then((response) => {
          response.data.push('default')
          commit('UPDATE_USER_PERMISSIONS', response.data)
        })
        .catch((error) => {
          console.dir(error)
        })
    }
  },
  resetUserAndPermissions({ commit }) {
    commit('RESET_USER_AND_PERMISSIONS')
  },
}

export default actions

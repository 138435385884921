/* eslint-disable no-param-reassign */

// eslint-disable-next-line import/no-self-import
import axios from 'axios'

const $axios = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL,
  // baseURL: 'http://192.168.29.212:5000/api/v1/',
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
  },
})

// Add token to params and header if we are NOT making a request to s3 bucket
$axios.interceptors.request.use((config) => {
  const token = localStorage.getItem('token') || ''
  if (!config.url?.includes('amazonaws')) {
    config.params = {
      ...config.params,
      token,
    }
    config.headers = {
      ...config.headers,
      Token: token,
    }
  }
  return config
})

$axios.interceptors.response.use(
  (response) => response,
  (err) => {
    console.log(err)
    const { status } = err.response || null
    const { data } = err.response || null
    const requestURL = err.config.url
    // If request is made to quickbooks modules and response is 302 then redirect to the response url
    if (requestURL.includes('qb/') && status && status === 302) {
      // data contains redirect url
      window.open(data, '_blank', 'toolbar=no,scrollbars=yes,resizable=no')
    }
    console.log(requestURL, status, data)
    return Promise.reject(err)
  }
)

export default $axios

import Vue from 'vue'

export default Vue.extend({
  data() {
    return {
      COMPANY_ENV: process.env.VUE_APP_COMPANY,
    }
  },
  methods: {
    openLoader(
      componentRef,
      loaderText = 'Loading..',
      loaderOptions = {
        backgroundColor: '#000',
        opacity: 0.4,
        color: '#fff',
        loader: 'spinner',
        canCancel: false,
      }
    ) {
      if (componentRef === null) return null

      return this.$loading.show(
        {
          container: componentRef,
          ...loaderOptions,
        },
        {
          after: this.$createElement(
            'h3',
            {
              attrs: {
                class: 'text-lg',
                style:
                  'position: absolute; top: 100%; left: 0; color: #fff; margin-top: 1.5rem; width: 100%;',
              },
            },
            loaderText
          ),
        }
      )
    },
    closeLoader(loader) {
      loader.hide()
    },
    // =============================================================================
    // Message to display when uploading is successful
    // =============================================================================
    showAlert(title, text, color) {
      this.$vs.notify({
        time: 10000,
        title,
        text,
        color,
        fixed: false,
      })
    },
    // =============================================================================
    // Open the loader in the main vxcard
    // =============================================================================
    openLoading(loadingText, id = 'div-with-loading') {
      this.$vs.loading({
        container: `#${id}`,
        text: loadingText,
        textAfter: true,
        scale: 0.6,
      })
    },
    // =============================================================================
    // Close the loader
    // =============================================================================
    closeLoading(id = 'div-with-loading') {
      this.$vs.loading.close(`#${id}> .con-vs-loading`)
    },
    handleError(error, closeLoading = true, id = 'div-with-loading') {
      console.dir(error)

      if (!error.response) {
        this.showAlert('NETWORK ERROR', 'Try Reloading', 'danger')
        console.log('NETWORK ERROR')
      } else {
        const code = error.response.status
        const response = error.response.data
        console.log(code, response)
        if (code === 400) {
          this.showAlert('Invalid Input', response, 'warning')
        } else if (code === 401) {
          this.showAlert('Permission Denied', 'Please contact the sytem admin', 'danger')
        } else {
          this.showAlert('Internal Server Error', response.message, 'danger')
        }
      }

      if (closeLoading) this.closeLoading(id)
    },
    formatNumber(num, decimalCount) {
      const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: decimalCount,
        maximumFractionDigits: decimalCount,
      })
      return formatter.format(num)
    },
    formatPercentage(num, decimalCount) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'percent',
        minimumFractionDigits: decimalCount,
        maximumFractionDigits: decimalCount,
      })
      return formatter.format(num / 100)
    },
    formatCurrency(price, decimalCount) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: decimalCount,
        maximumFractionDigits: decimalCount,
      })
      return formatter.format(price)
    },
    removeInputFormattings(num, type) {
      if (!num) return null
      if (type === 'INT') {
        return parseInt(num.replace(/([%$,])/g, ''), 10)
      }
      return parseFloat(num.replace(/([%$,])/g, ''))
    },
  },
})

import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        background: '#FFFFFF',
        primary: '#001449',
        secondary: '#F95503',
        neutral: '#667692',
        'neutral-50': '#F2F6FF',
        'neutral-500': '#39465E',
        surface: '#FFFFFF',
        error: '#B00020',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00',
      },
    },
  },
})

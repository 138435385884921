<!-- eslint-disable vue/block-lang -->
<!-- =========================================================================================
	File Name: App.vue
	Description: Main vue file - APP
	----------------------------------------------------------------------------------------
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Author: Pixinvent
	Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <v-app>
    <v-main>
      <div id="app" :class="vueAppClasses">
        <router-view @setAppClasses="setAppClasses" />
      </div>
    </v-main>
    <v-overlay :value="$store.state.bodyOverlay" color="#000" :opacity="0.8" :z-index="10">
      <!-- <v-progress-circular indeterminate size="48" color="secondary"></v-progress-circular> -->
      <v-dialog :value="$store.state.bodyOverlay" hide-overlay persistent :width="250">
        <v-card class="p-3" color="primary" dark>
          <!-- <v-card-text class="px-0 font-weight-bold">Please stand by</v-card-text> -->
          <v-progress-linear indeterminate color="secondary" class="mb-0"></v-progress-linear>
        </v-card>
      </v-dialog>
    </v-overlay>
  </v-app>
</template>

<script>
import themeConfig from '@/../themeConfig'

export default {
  data() {
    return {
      vueAppClasses: [],
      company: '',
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.theme': function (val) {
      this.toggleClassInBody(val)
    },
    // eslint-disable-next-line func-names
    '$vs.rtl': function (val) {
      document.documentElement.setAttribute('dir', val ? 'rtl' : 'ltr')
    },
  },
  beforeMount() {
    const themeColor = localStorage.getItem('themeColor')
    const theme = localStorage.getItem('theme')
    if (theme !== undefined || theme !== null) {
      this.$store.commit('UPDATE_THEME', theme)
    } else {
      this.$store.commit('UPDATE_THEME', 'semi-dark')
      localStorage.setItem('theme', 'semi-dark')
    }
    if (themeColor !== undefined || themeColor !== null) {
      this.$vs.theme({ primary: themeColor })
    } else {
      this.$vs.theme({ primary: '#7367F0' })
    }
  },
  mounted() {
    this.company = process.env.VUE_APP_COMPANY
    if (this.company === 'spectrum') document.title = 'iLink | Spectrum PCB'

    let link = document.querySelector("link[rel~='icon']")
    if (!link) {
      link = document.createElement('link')
      link.rel = 'icon'
      document.getElementsByTagName('head')[0].appendChild(link)
    }

    // eslint-disable-next-line no-unused-expressions
    process.env.VUE_APP_COMPANY === 'spectrum'
      ? (link.href = 'https://backend-assets.s3.ap-southeast-1.amazonaws.com/spectrumFavicon.ico')
      : 'https://linkage-email-pictures.s3.ap-south-1.amazonaws.com/favicon.png'

    this.toggleClassInBody(themeConfig.theme)
    this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)
    const vh = window.innerHeight * 0.01
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  },
  async created() {
    const dir = this.$vs.rtl ? 'rtl' : 'ltr'
    document.documentElement.setAttribute('dir', dir)

    window.addEventListener('resize', this.handleWindowResize)
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('resize', this.handleWindowResize)
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    toggleClassInBody(className) {
      if (className === 'dark') {
        if (document.body.className.match('theme-semi-dark')) {
          document.body.classList.remove('theme-semi-dark')
        }
        document.body.classList.add('theme-dark')
      } else if (className === 'semi-dark') {
        if (document.body.className.match('theme-dark')) {
          document.body.classList.remove('theme-dark')
        }
        document.body.classList.add('theme-semi-dark')
      } else {
        if (document.body.className.match('theme-dark')) {
          document.body.classList.remove('theme-dark')
        }
        if (document.body.className.match('theme-semi-dark')) {
          document.body.classList.remove('theme-semi-dark')
        }
      }
    },
    setAppClasses(classesStr) {
      this.vueAppClasses.push(classesStr)
    },
    handleWindowResize() {
      this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)

      // Set --vh property
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
    },
    handleScroll() {
      this.$store.commit('UPDATE_WINDOW_SCROLL_Y', window.scrollY)
    },
  },
}
</script>
<style>
.vs-notifications p {
  color: white !important;
}
</style>

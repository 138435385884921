/*= ========================================================================================
  File Name: state.js
  Description: Vuex Store - state
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== */

import navbarSearchAndPinList from '@/layouts/components/navbar/navbarSearchAndPinList'
import themeConfig, { colors } from '@/../themeConfig'

// /////////////////////////////////////////////
// State
// /////////////////////////////////////////////

const font = localStorage.getItem('font')
const collapseMenu = localStorage.getItem('collapse-menu')

const state = {
  AppActiveUser: null,
  AppActiveUserPermissions: null,
  bodyOverlay: false,
  isVerticalNavMenuActive: true,
  mainLayoutType: themeConfig.mainLayoutType || 'vertical',
  navbarSearchAndPinList,
  reduceButton: themeConfig.sidebarCollapsed,
  collapseMenu: collapseMenu !== undefined || collapseMenu !== null ? collapseMenu : true,
  verticalNavMenuWidth: 'default',
  verticalNavMenuItemsMin: false,
  scrollY: 0,
  starredPages: navbarSearchAndPinList.pages.data.filter((page) => page.is_bookmarked),
  theme: themeConfig.theme || 'semi-dark',
  themePrimaryColor: colors.primary,
  fontSize: font !== undefined || font !== null ? font : 'small', // options[String]  : "small"(default), "medium", "Large",

  // Can be used to get current window with
  // Note: Above breakpoint state is for internal use of sidebar & navbar component
  windowWidth: null,
}

export default state
